<template>
  <div>
    <b-card no-body>
      <base-actions-table
        :columns="fields"
        :records="records"
        :module="module"
        :busy="isBusy"
      >
        <!-- Observation -->
        <template #cell(observations)="data">
          <b-button
            v-if="data.item.observations"
            v-b-tooltip.hover.top="data.item.observations"
            size="sm"
            variant="warning"
            class="btn-icon"
          >
            <feather-icon icon="AlertCircleIcon" />
          </b-button>
          <div v-else>
            Sin observaciones
          </div>
        </template>
        <!-- actions -->
        <template v-slot:extendedactions="data">
          <b-button
            v-if="hasPermission(`${module}.details`)"
            v-b-tooltip.hover.top="'Ver detalles'"
            size="sm"
            variant="success"
            class="btn-icon mr-25"
            @click="details(data.data.item)"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
          <b-button
            v-if="hasPermission(`${module}.print`)"
            v-b-tooltip.hover.top="'Imprimir còdigo QR'"
            size="sm"
            variant="info"
            class="btn-icon mr-25"
          >
            <feather-icon icon="DownloadIcon" />
          </b-button>
        </template>
      </base-actions-table>
    </b-card>

    <detailt-order :record="record" />
  </div>
</template>

<script>
import {
	BCard,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import DetailtOrder from './components/DetailtOrder'
import BaseActionsTable from '@/components/base/BaseActionsTable'
import PermissionMixin from '@/mixins/PermissionMixin'

export default {
  inject: ['orderRepository'],
  components: {
    BCard,
    BButton,
    DetailtOrder,
    BaseActionsTable,
  },
  directives: {
		'b-tooltip': VBTooltip,
	},
	mixins: [PermissionMixin],
  data() {
    return {
      record: null,
      records: [],
			isBusy: false,
      fields: [
				{
					key: 'patient',
					label: 'Paciente',
					sortable: true,
				},
				{
					key: 'habitacion',
					label: 'Habitaciòn',
					sortable: true,
				},
				{
					key: 'type_menu',
					label: 'Tipo de menú',
					sortable: true,
				},
				{
					key: 'tipo_diet',
					label: 'Tipo de dieta',
					sortable: true,
				},
				{
					key: 'date',
					label: 'Fecha',
					sortable: true,
				},
				{
					key: 'hour',
					label: 'Hora',
					sortable: true,
				},
				{
					key: 'observations',
					label: 'Observación',
				},
				{
					key: 'delivery_person',
					label: 'Transportador',
					sortable: true,
				},
				{
					key: 'actions',
					label: 'Acciones',
				},
			],
			module: 'ordersent',
    }
  },
  async mounted() {
    await this.handleLoadData()
  },
  methods: {
    details(item) {
      const me = this
      me.record = item
      this.$bvModal.show('modal-detailt-order')
    },
    async handleLoadData() {
			const me = this
			try {
        me.isBusy = true
				const res = await me.orderRepository.getAll()
				const { data } = res
				data.forEach(el => {
					if (el.departure_at !== '') {
            let type_menu
						if (el.menu.type_menu == '1') {
							type_menu = 'Desayuno'
						} else if (el.menu.type_menu == '2') {
							type_menu = 'Almuerzo'
						} else {
							type_menu = 'Cena'
						}
						me.records.push({
							...el,
							patient: el.patient.first_name + ' ' + el.patient.last_name,
							tipo_diet: el.diet.name,
							type_menu: type_menu,
							delivery_person: el.delivery_person != '' ? el.delivery_person : 'Sin asignar',
						})
          }
				})
			} catch (error) {
				me.records = []
				// console.log(error)
			} finally {
        me.isBusy = false
      }
		},
  },
}
</script>

<style>

</style>
