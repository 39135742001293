<template>
  <div>
    <app-timeline v-if="record">
      <app-timeline-item
        title="Salida de cocina"
        :subtitle="record.departure_at"
        :variant="record.departure_at == '' ? 'primary' : 'danger'"
      />
      <app-timeline-item
        title="Entrega al paciente"
        :subtitle="record.arrival_at"
        :variant="record.arrival_at == '' ? 'primary' : 'danger'"
      />
    </app-timeline>
  </div>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
		AppTimelineItem,
  },
  props: {
    record: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style>

</style>
