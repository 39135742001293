const API_URL = process.env.VUE_APP_API_URL
const API_VERSION = process.env.VUE_APP_API_VERSION

const API_ROUTES = {
	order: {
		get: `${API_URL}/${API_VERSION}/menu-order`,
	},
	orderTimeline: {
		save: `${API_URL}/${API_VERSION}/menu-order-timeline`,
	},
	type_diet: {
		get: `${API_URL}/${API_VERSION}/diets`,
	},
	images: {
		image: `${API_URL}/storage/`,
	},
	report: {
		get: `${API_URL}/api/report-excel-menu-orders`,
	},
}

export { API_ROUTES }
